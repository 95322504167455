import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

// Auto Logout
const MINUTES_UNITL_AUTO_LOGOUT = 60; // in mins
const CHECK_INTERVAL = 5000; // in ms

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  readonly authUrl = environment.serverUrl;
  private loggedIn = false;

  // Auto Logout
  get lastAction() {
    return parseInt(localStorage.getItem('lastAction'));
  }
  set lastAction(value) {
    localStorage.setItem('lastAction', String(value));
  }

  constructor(private http: HttpClient,
              private router: Router) {
    this.check();
    this.initListener();
    this.initInterval();
    // look at localStorage to check if the user is logged in
    this.loggedIn = !!localStorage.getItem('auth_token');
  }

  /**
   * Check if the user is logged in
   */
  isLoggedIn() {
    return this.loggedIn;
  }

  /**
   * Log the user in
   */

  login(login: string, password: string): Observable<string> {
    const headers = new HttpHeaders({
      'content-type': 'application/json'
    });
    const options = { headers: headers };

    return this.http.post(`${this.authUrl}/Account/Login`, { Email: login, Password: password }, options)
      .pipe(tap((res: any) => {
        if (res) {
          localStorage.setItem('auth_token', res.token);
          localStorage.setItem('user', login);
          localStorage.setItem('userId', res.id);
          this.loggedIn = true;
          if (localStorage.route && localStorage.route != '/') {
            this.router.navigate([localStorage.route]);
          } else {
            this.router.navigate(['/main/dashboard']);
          }
        }
      }))
      .pipe(catchError(this.errorHandlerLogin));
  }

  /**
   * Log the user out
   */
  logout() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('user');
    localStorage.removeItem('userId');
    localStorage.removeItem('route');
    this.loggedIn = false;
    this.router.navigate(['/login']);
  }

  /**
   * Handle any errors from the API
   */
  errorHandlerLogin(error: HttpErrorResponse) {
    return throwError(() => new Error(error.error || 'ERROR'));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(() => new Error(error.message || 'ERROR'));
  }

  // Auto Logout
  initListener() {
    document.body.addEventListener('click', () => this.reset());
  }

  reset() {
    this.lastAction = Date.now();
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout && this.isLoggedIn()) {
      this.logout();
    } else {
      return true;
    }
  }

}
