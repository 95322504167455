import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../core/auth/auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MainService} from "../main/shared/main.service";
import {catchError} from "rxjs/operators";
import {empty} from "rxjs";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  forgotPassForm: FormGroup;
  noSuchUser = false;
  focusedInputEmail:boolean = false;
  focusedInputPass:boolean = false;
  showPass:boolean = false;
  visibleLoginForm = true;
  visibleForgotPassForm = false;
  visibleCheckEmail = false;
  // emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  preloader = false;

  constructor(private authService: AuthService,
              private _mainService: MainService,
              private fb: FormBuilder) { }

  ngOnInit() {
    this.initForms();
  }

  initForms() {
    this.loginForm = this.fb.group({
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.minLength(3),
        Validators.maxLength(30)
      ]
      ],
      pass: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30)
      ]
      ]
    });
    this.forgotPassForm = this.fb.group({
      email: ['', [
        Validators.required,
        Validators.email
      ]
      ]
    });
  }

  onSubmitLogin(inputEmail, inputPass) {
    this.preloader = true;

    this.noSuchUser = false;
    const validEmail = this.loginForm.controls['email'].invalid;
    const validPass = this.loginForm.controls['pass'].invalid;
    if (validEmail || validPass) {
      inputEmail.focus();
      inputEmail.blur();
      inputPass.focus();
      inputPass.blur();
      this.preloader = false;
      return
    }

    const email = this.loginForm.get('email').value;
    const pass = this.loginForm.get('pass').value;

    this.authService.login(email, pass)
      .pipe(
        catchError(() => {
          this.noSuchUser = true;
          this.preloader = false;
          return empty()
        })
      )
      .subscribe();
  }

  isLoginFormInvalid() {
    const validEmail = this.loginForm.controls['email'].invalid;
    const validPass = this.loginForm.controls['pass'].invalid;

    if (!validEmail && !validPass) {
      return false;
    } else {
      return true;
    }
  }

  ngOnDestroy() {

  }
}
