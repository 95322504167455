<section class="login">
  <div class="login__wrapper">
    <div class="login__logo" *ngIf="visibleLoginForm">
      <img src="/assets/img/avem-aero-logo.png" alt="logo">
    </div>
    <div class="login__image" *ngIf="visibleForgotPassForm">
      <img src="assets/img/icon-lock.svg" alt="icon lock">
    </div>
    <div class="login__image" *ngIf="visibleCheckEmail">
      <img src="assets/img/icon-envelope.svg" alt="icon envelope">
    </div>

    <form [formGroup]="loginForm"
          (submit)="onSubmitLogin(inputEmailLogin, inputPassLogin)"
          *ngIf="visibleLoginForm"
          class="login__form">
      <div class="login__form-input">
        <div class="login__input-wrapper">
          <input type="email" id="inputEmail"
                 formControlName="email"
                 #inputEmailLogin
                 placeholder="Email"
                 (focus)="noSuchUser = false; focusedInputEmail = true"
                 (blur)="focusedInputEmail = false">
          <label [class.focus]="focusedInputEmail"
                 [class.error]="noSuchUser">
            <span class="login__input-image">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 8C10.2 8 12 6.2 12 4C12 1.8 10.2 0 8 0C5.8 0 4 1.8 4 4C4 6.2 5.8 8 8 8ZM8 10C5.3 10 0 11.3 0 14V16H16V14C16 11.3 10.7 10 8 10Z" fill="#CACACA"/>
              </svg>
            </span>
          </label>
        </div>
        <div ngxErrors="email" class="login__form-error">
          <div ngxError="required" when="touched">
            Email is required!
          </div>
          <div ngxError="email" when="dirty">
            You entered an invalid email!
          </div>
          <div ngxError="minlength" when="dirty">
            Email must be at least 3 characters!
          </div>
          <div ngxError="maxlength" when="dirty">
            Email must be no more than 30 characters!
          </div>
        </div>
        <div *ngIf="noSuchUser" class="login__form-error">
          Invalid e-mail address or you are not registered!
        </div>
      </div>
      <div class="login__form-input login__form-input--with-btn">
        <div class="login__input-wrapper">
          <input id="inputPass"
                 formControlName="pass"
                 #inputPassLogin
                 placeholder="Password"
                 [type]="showPass ? 'text' : 'password'"
                 (focus)="noSuchUser = false; focusedInputPass = true"
                 (blur)="focusedInputPass = false">
          <label [class.focus]="focusedInputPass"
                 [class.error]="noSuchUser">
            <span class="login__input-image">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                  <path d="M5.03705 16C7.82222 16 10.0741 13.7481 10.0741 10.963C10.0741 10.3704 9.95556 9.77779 9.77779 9.1852L16 2.96296L16 0L13.037 2.5903e-07L13.037 1.77778L11.2593 1.77778L11.2593 3.55556L9.48148 3.55556L6.81483 6.22221C6.28148 6.04445 5.68889 5.92593 5.03705 5.92593C2.25185 5.92593 2.98337e-05 8.17778 3.00772e-05 10.9629C3.03207e-05 13.7481 2.25185 16 5.03705 16ZM3.55556 10.6667C4.56298 10.6667 5.33333 11.437 5.33333 12.4444C5.33333 13.4519 4.56298 14.2222 3.55556 14.2222C2.54813 14.2222 1.77778 13.4519 1.77778 12.4444C1.77778 11.437 2.54813 10.6667 3.55556 10.6667Z" fill="#CACACA"/>
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="16" height="16" fill="white" transform="translate(16 16) rotate(180)"/>
                  </clipPath>
                </defs>
              </svg>
            </span>
          </label>
          <button type="button"
                  class="login__form-input-btn"
                  [class.active]="showPass"
                  (click)="showPass = !showPass">
          </button>
        </div>
        <div ngxErrors="pass" class="login__form-error">
          <div ngxError="required" when="touched">
            Password is required!
          </div>
          <div ngxError="minlength" when="dirty">
            Password must be at least 6 characters!
          </div>
          <div ngxError="maxlength" when="dirty">
            Password must be no more than 30 characters!
          </div>
        </div>
        <div *ngIf="noSuchUser" class="login__form-error">
          Wrong Password, Please try again!
        </div>
      </div>
      <div class="login__form-submit">
        <button class="login__submit-btn"
                [class.login__form-submit--disabled]="isLoginFormInvalid()"
                type="submit">
          Login
        </button>
      </div>
    </form>
  </div>
</section>

<div class="preloader" *ngIf="preloader">
  <div id="floatingCirclesG">
    <div class="f_circleG" id="frotateG_01"></div>
    <div class="f_circleG" id="frotateG_02"></div>
    <div class="f_circleG" id="frotateG_03"></div>
    <div class="f_circleG" id="frotateG_04"></div>
    <div class="f_circleG" id="frotateG_05"></div>
    <div class="f_circleG" id="frotateG_06"></div>
    <div class="f_circleG" id="frotateG_07"></div>
    <div class="f_circleG" id="frotateG_08"></div>
  </div>
</div>
