import { Injectable } from '@angular/core';
import { Subject, throwError } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class MainService {
  public preloader$: Subject<any> = new Subject();
  public alertSuccess$: Subject<any> = new Subject();
  public alertError$: Subject<any> = new Subject();
  public alertErrorDesc$: Subject<any> = new Subject();
  public mainFull$: Subject<any> = new Subject();

  constructor() { }

  errorHandler(error: HttpErrorResponse) {
    return throwError(() => new Error (error.message || 'ERROR'));
  }

  errorHandlerDescription(error: HttpErrorResponse) {
    return throwError(() => {
      if (typeof error.error === 'object') {
        return error.error;
      } else {
        return new Error (error.error || 'ERROR');
      }
    });
  }
}
